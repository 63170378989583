import { batchPurchasesStockSync, TPurchase } from "@/api";
import { useEffect, useState } from "react";
import {
  AutocompleteInput,
  Button,
  CreateButton,
  Datagrid,
  DateField,
  DateTimeInput,
  FilterButton,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  TextField,
  TextInput,
  TopToolbar,
  useListFilterContext,
  useListPaginationContext,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { ADMINS, PURCHASES, SELLERS, SUPPLIERS } from "@/config/resources";
import { deliveryStatus, paymentStatus, status } from "../../../data/purchases";
import { useAdmin } from "../../../hooks/admin";
import { CustomPagination } from "../../shared/pagination";
import { PurchasesExport } from "../export";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import CalculateIcon from "@mui/icons-material/Calculate";
import { PurchasesImportButton } from "../import";
import { ClearFiltersButton } from "../../../components/ClearFiltersButton";
import { usePaymentMethods } from "../../../hooks/payment-methods";
import { Stack, Chip, SvgIcon, Box } from "@mui/material";
import { formatStatusColor } from "../../../hooks/purchase";
import {
  mdiTruckOutline,
  mdiCreditCardOutline,
  mdiPackageVariantClosed,
} from "@mdi/js";
import { Swal } from "@/core/swal";
import { PurchasesListExport } from "./actions/list-export";
import { i18nProvider } from "@/providers/i18n";

const BatchStockSyncButton = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "¿Seguro que quieres correr el proceso de batch?",
      showCancelButton: true,
      confirmButtonText: "Sí, seguro",
      cancelButtonText: "Cancelar",
    });
    if (!isConfirmed) return;

    setLoading(true);
    try {
      await batchPurchasesStockSync();
      refresh();
      notify("Se realizó el proceso para pedidos quietos por stock", {
        type: "success",
      });
    } catch (error) {
      console.error(error);
      notify("Ocurrió un error en el proceso de batch", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <Button
      label="Batch"
      onClick={onClick}
      disabled={loading}
      startIcon={<CloudSyncIcon />}
    />
  );
};

const CalculateButton = () => {
  const redirect = useRedirect();
  const onClick = () => redirect(`/${PURCHASES}/quoter`);

  return (
    <Button label="Cotizador" onClick={onClick} startIcon={<CalculateIcon />} />
  );
};

const PurchaseActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      <ClearFiltersButton />
      <FilterButton />
      {hasPermission("purchase.create") && <CreateButton />}
      {hasPermission("purchase.export") && <PurchasesExport />}
      {hasPermission("purchase.export") && <PurchasesListExport />}
      {hasPermission("purchase.batchsyncstock") && <BatchStockSyncButton />}
      {hasPermission("purchase.quoter") && <CalculateButton />}
      {hasPermission("purchase.import") && <PurchasesImportButton />}
    </TopToolbar>
  );
};

const PurchaseListPagination = () => {
  const DEFAULT_PER_PAGE = 10;
  return (
    <Box padding={"16px"} textAlign={"center"}>
      Se muestran solo {DEFAULT_PER_PAGE} filas. <b>Usa los filtros</b> para
      encontrar el recurso que buscas.
    </Box>
  );
};

export const PurchaseList = () => {
  const { hasPermission } = useAdmin();
  const paymentMethods = usePaymentMethods();

  if (!hasPermission("purchase.list")) return null;

  return (
    <List
      perPage={100}
      filters={[
        <SelectArrayInput
          source="paymentMethod"
          choices={paymentMethods.map((pm) => ({
            code: pm.code,
            name: i18nProvider.translate(
              `resources.${PURCHASES}.fields.paymentMethods.${pm.code}`
            ),
          }))}
          optionValue="code"
          alwaysOn
        />,
        <SelectArrayInput
          source="status"
          choices={status}
          optionValue="code"
          alwaysOn
        />,
        <SelectArrayInput
          source="paymentStatus"
          choices={paymentStatus}
          optionValue="code"
        />,
        <SelectArrayInput
          source="deliveryStatus"
          choices={deliveryStatus}
          optionValue="code"
        />,
        <ReferenceInput source="sellerId" reference={SELLERS} alwaysOn>
          <AutocompleteInput
            label="Vendedor"
            filterToQuery={(searchText: string) => ({ email: searchText })}
            optionText="email"
          />
        </ReferenceInput>,
        <ReferenceInput source="supplierId" reference={SUPPLIERS} alwaysOn>
          <AutocompleteInput
            label="Proveedor"
            filterToQuery={(searchText: string) => ({ name: searchText })}
            optionText="name"
          />
        </ReferenceInput>,
        <TextInput source="purchaseNumber" alwaysOn />,
        <DateTimeInput
          source="createdAtGte"
          label="Creado desde"
          parse={(v: string) =>
            v ? new Date(v).toISOString().replace("T", " ") : v
          }
          alwaysOn
        />,
        <DateTimeInput
          source="createdAtLte"
          label="Creado hasta"
          parse={(v: string) =>
            v ? new Date(v).toISOString().replace("T", " ") : v
          }
          alwaysOn
        />,
      ]}
      sort={{ field: "createdAt", order: "DESC" }}
      exporter={false}
      actions={<PurchaseActions />}
      queryOptions={{ meta: { params: { includeSubPurchases: false } } }}
    >
      <Datagrid
        rowClick={hasPermission("purchase.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <DateField source="createdAt" showTime />
        <TextField source="purchaseNumber" sortable={false} />
        <FunctionField
          source="paymentMethod"
          sortable={false}
          render={(record: any) =>
            i18nProvider.translate(
              `resources.${PURCHASES}.fields.paymentMethods.${record.paymentMethod}`
            )
          }
        />
        <NumberField source="total" />
        <ReferenceField
          source="sellerId"
          sortable={false}
          reference={SELLERS}
          link={hasPermission("seller.view") ? "show" : false}
        >
          <TextField source="email" />
        </ReferenceField>
        <FunctionField
          source="creationInfo"
          sortable={false}
          render={(record: any) => (
            <Stack direction="column" sx={{ fontSize: "0.7rem" }}>
              {record.origin?.name === "backoffice" && (
                <span>
                  Creado por: &nbsp;
                  <ReferenceField
                    source="createdBy"
                    sortable={false}
                    reference={ADMINS}
                    link={hasPermission("admin.view") ? "show" : false}
                  >
                    <TextField source="email" sx={{ fontSize: "0.7rem" }} />
                  </ReferenceField>
                </span>
              )}
              {record?.supplierId && (
                <span>
                  Distribuidor: &nbsp;
                  <ReferenceField
                    source="supplierId"
                    sortable={false}
                    reference={SUPPLIERS}
                    link={hasPermission("supplier.view") ? "show" : false}
                  >
                    <TextField source="name" sx={{ fontSize: "0.7rem" }} />
                  </ReferenceField>
                </span>
              )}
              {record.kam && <span>KAM:&nbsp; {record.kam}</span>}
              {record.origin && <span>Origen: {record.origin.name}</span>}
            </Stack>
          )}
        />
        <FunctionField
          label="Productos procesados"
          render={(purchase: TPurchase) =>
            `${purchase.products.reduce(
              (qty, p) => qty + (p.subPurchaseId ? 1 : 0),
              0
            )} / ${purchase.products.length}`
          }
        />
        <FunctionField
          source="status"
          render={(record: any) => (
            <Chip
              size="small"
              icon={
                <SvgIcon>
                  <path d={mdiPackageVariantClosed} />
                </SvgIcon>
              }
              variant="outlined"
              color={formatStatusColor(record.status)}
              sx={{ width: "fit-content" }}
              label={status.find((s) => s.code === record.status)?.name}
            />
          )}
        />
        <FunctionField
          source="deliveryStatus"
          render={(record: any) => (
            <Chip
              size="small"
              icon={
                <SvgIcon>
                  <path d={mdiTruckOutline} />
                </SvgIcon>
              }
              variant="outlined"
              color={formatStatusColor(record.deliveryStatus)}
              sx={{ width: "fit-content" }}
              label={
                deliveryStatus.find((s) => s.code === record.deliveryStatus)
                  ?.name
              }
            />
          )}
        />
        <FunctionField
          source="paymentStatus"
          render={(record: any) => (
            <Chip
              size="small"
              icon={
                <SvgIcon>
                  <path d={mdiCreditCardOutline} />
                </SvgIcon>
              }
              variant="outlined"
              color={formatStatusColor(record.paymentStatus)}
              sx={{ width: "fit-content" }}
              label={
                paymentStatus.find((s) => s.code === record.paymentStatus)?.name
              }
            />
          )}
        />
      </Datagrid>
    </List>
  );
};
